import React from "react";

const CustomButtons = ({
  next,
  previous,

  leftArr,
  rightArr,
  show = true,
  ...rest
}) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  // console.log(show);
  return (
    show && (
      <div className="slider-btns">
        <button
          className={`btn ${currentSlide === 0 ? "disable" : ""}`}
          onClick={() => previous()}
        >
          <img className="arrow" src={leftArr} alt="Left Icon"></img>
        </button>
        <button className="btn" onClick={() => next()}>
          <img className="arrow" src={rightArr} alt="Right Icon"></img>
        </button>
      </div>
    )
  );
};
export default CustomButtons;
